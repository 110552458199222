import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { Loader } from "../component/Loader/loader";
import { Data, AdminData } from "../utils/index";
import AdminView from "../component/Admin/view";
function Root() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route>
          {Data.map(({ component: Component, path }, i) => (
            <Route path={path} element={<Component />} key={i} />
          ))}
        </Route>
        <Route path="/admin" element={<AdminView />}>
          {AdminData?.map(({ path, component: Component }, i) => (
            <Route path={path} element={<Component />} key={i} />
          ))}
        </Route>
        <Route
          path={"*"}
          element={
            <div className="flex w-full h-screen justify-center items-center">
              <h1>404 Not found 🙁</h1>
            </div>
          }
        />
      </Routes>
    </Suspense>
  );
}

export default Root;
