import React from "react";
import { Layout, Menu } from "antd";
import Logo from "../../assets/icons/logo game.svg";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { Outlet } from "react-router-dom";
const { Header, Footer, Sider } = Layout;
const AdminView = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {contextHolder}
      <Sider collapsible>
        <div className="logo" />
        <Menu theme="dark" defaultSelectedKeys={["3"]} mode="inline">
          <Menu.Item
            style={{ marginTop: "20px", height: "60px", background: "#903E97" }}
            onClick={() => navigate("/")}
            key="1"
            icon={
              <img
                src={Logo}
                alt="Game festival logo"
                className="w-[70px] h-[72px] z-20 "
              />
            }
            minHeight={100}
          ></Menu.Item>
          <Menu.Item
            key="3"
            style={{
              marginTop: "20px",
            }}
            onClick={() => {
              navigate("/admin");
            }}
          >
            <p className="text-[#fff]">Barcha forumlar </p>
          </Menu.Item>

          <Menu.Item
            key="4"
            style={{
              marginTop: "20px",
              height: "50px",
            }}
            onClick={() => {
              navigate("/admin?fest=International Game and Animation Fest");
            }}
          >
            <p className="text-[#fff] text-wrap leading-[20px]">
              International Game and Animation Fest
            </p>
          </Menu.Item>
          <Menu.Item
            key="5"
            style={{
              marginTop: "20px",
              height: "50px",
            }}
            onClick={() => {
              navigate("/admin?fest=Game and Animation Expo");
            }}
          >
            <p className="text-[#fff] text-wrap leading-[20px]">
              Game and Animation Expo
            </p>
          </Menu.Item>
          <Menu.Item
            key="6"
            style={{
              marginTop: "20px",
            }}
            onClick={() => {
              navigate("/admin?fest=GAME FORUM");
            }}
          >
            <p className="text-[#fff] text-wrap leading-[20px]">GAME FORUM</p>
          </Menu.Item>
          <Menu.Item
            key="7"
            style={{
              marginTop: "20px",
            }}
            onClick={() => {
              navigate("/admin?fest=Animation Forum");
            }}
          >
            <p className="text-[#fff] text-wrap leading-[20px]">
              Animation Forum
            </p>
          </Menu.Item>
          <Menu.Item
            key="8"
            style={{
              marginTop: "20px",
            }}
            onClick={() => {
              navigate("/admin/E-Sports");
            }}
          >
            <p className="text-[#fff] text-wrap leading-[20px]">
              E-Sports Tournament
            </p>
          </Menu.Item>
          <Menu.Item
            key="9"
            style={{
              marginTop: "20px",
            }}
            onClick={() => {
              navigate("/admin?fest=Music Festival");
            }}
          >
            <p className="text-[#fff] text-wrap leading-[20px]">
              Music Festival
            </p>
          </Menu.Item>
          <Menu.Item
            key="10"
            style={{
              marginTop: "20px",
            }}
            onClick={() => {
              navigate("/admin?fest=COSPLAY SHOW");
            }}
          >
            <p className="text-[#fff] text-wrap leading-[20px]">COSPLAY SHOW</p>
          </Menu.Item>
          <div className="w-full h-[1px] bg-white my-[20px]"></div>
          <Menu.Item
            key="11"
            style={{
              marginTop: "20px",
            }}
            onClick={() => {
              navigate("/admin/blog");
            }}
          >
            <p className="text-[#fff] text-wrap leading-[20px]">Bloglar</p>
          </Menu.Item>

          <Menu.Item
            key="12"
            style={{
              marginTop: "20px",
            }}
            onClick={() => {
              navigate("/admin/media");
            }}
          >
            <p className="text-[#fff] text-wrap leading-[20px]">Media</p>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }} />
        <Outlet />
        <Footer style={{ textAlign: "center" }}>GameDev Admin Panel</Footer>
      </Layout>
    </Layout>
  );
};

export default AdminView;
