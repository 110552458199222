import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import Root from "./root/index";
import "./index.css";
import store from "./redux/store/store";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import ToastProvider from "./component/Toast/ToastProvider";
import "react-toastify/dist/ReactToastify.css";
import "react-international-phone/style.css";
import "./i18n"; // Your i18n initialization

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Router>
      <Helmet>
        <title>Game Fest</title>
        <meta property="og:type" content="website" />
        {/* <meta property="og:url" content="https://game.fest.uz/" /> */}
        {/* <meta
          property="og:image"
          content="https://i.kinja-img.com/image/upload/c_fit,q_60,w_645/a7328a05e87b4bf7ff524c50e6111521.jpg"
        /> */}
        {/* <meta
          property="image"
          content="https://i.kinja-img.com/image/upload/c_fit,q_60,w_645/a7328a05e87b4bf7ff524c50e6111521.jpg"
        /> */}
        <meta name="description" content="Game Fest" data-rh="true" />
        <meta
          name="keywords"
          content="Game Fest, Game uz, Game UZ, GAME UZ, GAME UZBEKISTAN"
        />
        <link rel="canonical" href="https://game.fest.uz/" />
        <meta property="og:title" content={"IT skills"} />
        <meta property="og:description" content={"Game Fest"} />
      </Helmet>
      <Provider store={store}>
        <ToastProvider>
          <Root />
        </ToastProvider>
      </Provider>
    </Router>
  </>
);
